<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>내정보</h2>
          </div>
          <section class="study_myinfo_list_area" style="margin-top:-16px;" >
            <div style="width:90%;margin:auto;">
              <section class="study_main_box">
                <div class="signup_left_box"></div>
                <div class="signup_right_box">
                  <div style="width: 100%;">
                    <form class="login_form" alt="로그인">
                      <div class="login_form_box">
                        <input type="text" class="login_input_box_01_round" v-model="user.name" placeholder="Name" style="margin-bottom:10px; padding: 0px 20px !important;">
                        <input type="text" class="login_input_box_01_round" v-model="user.english_name" placeholder="English Name" style="margin-bottom:10px; padding: 0px 20px !important;">
                        <input type="text" class="login_input_box_01_round" v-model="user.email" placeholder="Email" style="margin-bottom:10px; padding: 0px 20px !important;">
                        <input type="text" class="login_input_box_01_round" v-model="user.age" placeholder="Age" style="margin-bottom:10px; padding: 0px 20px !important;">
                        <input type="text" class="login_input_box_01_round" v-model="user.gender" placeholder="Gender" style="margin-bottom:10px; padding: 0px 20px !important;">
                        <input type="text" class="login_input_box_01_round" v-model="user.phone_number" placeholder="Phone Number" style="margin-bottom:10px; padding: 0px 20px !important;">
                        <input type="text" class="login_input_box_01_round" v-model="user.parents_phone_number" placeholder="Parents Phone Number" style="padding: 0px 20px !important;">
                      </div>
                    </form>
                    <div style="width:230px;margin:0px auto 30px;text-align:left;">
                      <button type="button" class="btn loginBtn_sheet" @click.prevent="updateProfile" style="width: 240px; background: url(/image/sub/member_arr_01.png) no-repeat 190px center #ffa813;">
                        <a href="#">Update Information</a>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'my-page' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import User from '@/models/User'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'MyPage.MyInfo',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      user: [],
      loading: false
    }
  },
  mounted () {
    this.loadMyProfile()
  },
  methods: {
    async loadMyProfile (id) {
      try {
        this.loading = true
        const response = await User.myProfile()
        this.user = response.data.userData
        this.loading = false
      } catch (e) {
        // Do Nothing Here ...
      }
    },
    async updateProfile () {
      try {
        const response = await User.update(this.user)
        this.$swal({
          title: 'Success!',
          text: response.data,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } catch (e) {
        this.$swal({
          title: 'Error',
          text: e.response.data.message,
          icon: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
